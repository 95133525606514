const title = [
    'ㄱ', '거', '건', '건ㅁ', '건무', '건물', '건물ㅈ', '건물주', '건물죽', '건물주가', '건물주가 ', '건물주가 ㄷ',
    '건물주가 도', '건물주가 되', '건물주가 됙', '건물주가 되고', '건물주가 되고 ', '건물주가 되고 ㅅ', '건물주가 되고 시',
    '건물주가 되고 싶', '건물주가 되고 싶ㅇ', '건물주가 되고 싶으', '건물주가 되고 싶은', '건물주가 되고 싶은 D', '건물주가 되고 싶은 De',
    '건물주가 되고 싶은 Dev', '건물주가 되고 싶은 Deve', '건물주가 되고 싶은 Devel', '건물주가 되고 싶은 Develo', '건물주가 되고 싶은 Develop',
    '건물주가 되고 싶은 Develope', '건물주가 되고 싶은 Developer'
].reverse()

// const title = [
//     'ㅎ', '혜', '혬', '혜미', '혜민', '혜민ㅇ', '혜민이', '혜민잉', '혜민이으', '혜민이의', '혜민이의 ',
//     '혜민이의 ㅅ', '혜민이의 새', '혜민이의 생', '혜민이의 생ㅇ', '혜민이의 생이', "혜민이의 생일", '혜민이의 생일ㅇ', "혜민이의 생일으", "혜민이의 생일을", '혜민이의 생일을 ',
//     '혜민이의 생일을 ㅊ', "혜민이의 생일을 추", "혜민이의 생일을 축", "혜민이의 생일을 축ㅎ", "혜민이의 생일을 축하", "혜민이의 생일을 축핳", "혜민이의 생일을 축하하", "혜민이의 생일을 축하합",
//     '혜민이의 생일을 축하합ㄴ', "혜민이의 생일을 축하합니", "혜민이의 생일을 축하합닏", "혜민이의 생일을 축하합니다", "혜민이의 생일을 축하합니다."
// ].reverse()

export default title