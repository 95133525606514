import React from 'react'
import YouTube from 'react-youtube';

import Header from './../components/Header';
import { Box } from '@mui/material';

const LandingPage = () => {
  const opts = {
    height: '195',
    width: '320',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <Box>
        <Header/>
        <Box sx={{p:"32px", display:"flex", justifyContent:"center"}}>
          {/* <YouTube videoId="oiZZIR4Q39w" opts={opts} /> */}
        </Box>
    </Box>
  )
}

export default LandingPage