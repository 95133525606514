import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Button, Tooltip, IconButton, TextField } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import LightModeIcon from "@mui/icons-material/LightMode";

import useInterval from "./../utils/useInterval";
import titleText from "./../assets/title";

const blink = keyframes`{
    50% {
        border-color: transparent;
    }
}`;

const Typing = styled.div`
  & {
    animation: ${blink} 0.5s step-end infinite alternate;
    overflow: hidden;
    white-space: nowrap;
    border-right: 3px solid;
    font-size: 1.5rem;
    color: #62ddfd;
    line-height: 1.5;
    font-weight: bold;
  }
  &:hover {
    color: white;
  }
`;

const Header = () => {
  const navigate = useNavigate();

  const [openSerach, setOpenSerach] = useState(false);
  const [display, setDisplay] = useState("none");

  const goToLandingPage = () => {
    navigate("/");
  };
  const handleSearch = () => {
    setOpenSerach(!openSerach);
  };

  useEffect(() => {
    if (!openSerach) {
      setTimeout(() => {
        setDisplay("none");
      }, 850);
    } else {
      setDisplay("");
    }
  }, [openSerach]);

  return (
    <Box
      sx={{ height: "3.5rem", background: "#252627", borderBottom: 1, borderColor: "#ffffff29" }}
    >
      <Grid container sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
        <Grid
          item
          xs={10}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Button onClick={goToLandingPage}>
            <Tooltip title="Home" arrow>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ color: "white", fontSize: "1.5rem" }}>{"<"}</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Title />
                </Box>
                <Typography sx={{ color: "white", fontSize: "1.5rem" }}>{"/>"}</Typography>
              </Box>
            </Tooltip>
          </Button>

          <Box sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center" }}>
            <Box sx={{ mr: "10px" }}>
              <Typography
                sx={{ fontSize: "1.5rem", cursor: "pointer", "&:hover": { color: "#bbb" } }}
              >
                Category
              </Typography>
            </Box>
            <Box sx={{ mr: "10px", display: "flex" }}>
              <Tooltip title="Search" arrow>
                <IconButton onClick={handleSearch}>
                  <SearchIcon sx={{ color: "white", "&:hover": { color: "#bbb" } }} />
                </IconButton>
              </Tooltip>
              <Box sx={{ maxWidth: openSerach ? "300px" : 0, transition: ".8s" }}>
                <TextField
                  size="small"
                  sx={{
                    background: "#363636",
                    borderRadius: "10px",
                    maxWidth: openSerach ? "300px" : 0,
                    transition: ".8s",
                  }}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: "#5d5d5f", mr: 1 }} />,
                    style: {
                      color: "white",
                      maxWidth: openSerach ? "300px" : 0,
                      display: display,
                      transition: ".8s",
                    },
                  }}
                  placeholder="검색 키워드를 입력해주세요."
                />
              </Box>
            </Box>
            <Box sx={{ mr: "10px" }}>
              <Tooltip title="Light Mode" arrow>
                <IconButton>
                  <LightModeIcon sx={{ color: "white", "&:hover": { color: "#bbb" } }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Title = () => {
  const [title] = useState(titleText.slice());
  const [content, setContent] = useState("");
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      if (title.length === 1) setIsRunning(false);
      setContent(title.pop());
    },
    isRunning ? 100 : null
  );

  return <Typing>{content}</Typing>;
};

export default Header;
